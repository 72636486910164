/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 1vh;
  background: #bcbcbc;
}
.dark ::-webkit-scrollbar-thumb {
  background: #333333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
.dark ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
.dark ::-webkit-scrollbar-corner {
  background: transparent;
}
