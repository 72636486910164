.Toastify__toast-container--top-right {
  top: 2.6rem;
  right: 1em;
}

.Toastify__toast--default {
  background: #ffffff7d;
  color: #7d7d7d;
}
.Toastify__progress-bar--default {
  background: white;
}

.Toastify__toast--error {
  background: #e74c3c7d;
}

.Toastify__toast--success {
  background: #07bc0c7d;
}
